import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { disposalManageInsightsActions } from '@redux/reducers/disposals/disposalInsights/disposalInsightsReducer';
import { getDefaultError } from '@shared/utils/common';
import { DisposalInsights } from '@apis/disposalsApi.types';

const $http = getHTTPClient();

export function* getDisposalManageInsightsData(action: PayloadAction<Id>) {
  yield put(disposalManageInsightsActions.setLoading(true));

  try {
    const { data } = yield call(() => $http.get<DisposalInsights>(`/disposals/${action.payload}/insights`));

    yield put(disposalManageInsightsActions.getDataSuccess(data));
  } catch (e) {
    yield put(disposalManageInsightsActions.getDataFailure(getDefaultError(e?.response?.data?.message)));
  }
}
